#sec-code .input-group {
  display: flex;
  align-items: center;
}
#sec-code .input-group > label,
#sec-code .input-group > span {
  margin-right: 0.1em;
}
#sec-code .input-group > label:last-child,
#sec-code .input-group > span:last-child {
  margin-right: 0;
}
@media (min-width: 800px) {
  #sec-code .input-group > label,
  #sec-code .input-group > span {
    margin-right: 0.2em;
  }
  #sec-code .input-group > label:last-child,
  #sec-code .input-group > span:last-child {
    margin-right: 0;
  }
}

#sec-code label {
  width: 40px;
  height: 46px;
  position: relative;
}
#sec-code input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 46px;
  opacity: 0;
}

#sec-code label > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 3px;
  background-color: #ffffff;
  
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #1C78AD;

  text-transform: uppercase; 
}

#sec-code label:focus-within > div {
  box-shadow: inset 0 2px 5px 0 rgba(9, 30, 66, 0.2);
  border: none;
  border-radius: 3px;
  background-color: #1C78AD;
  color: white;
  font-weight: 300;
  outline: none;
}

#sec-code label:enabled > div {
  box-shadow: inset 0 2px 5px 0 rgba(9, 30, 66, 0.2);
  border: none;
  border-radius: 3px;
  background-color: #2F97BC;
  color: white;
  outline: none;
}

#sec-code hr {
  border: none;
  position: absolute;
  bottom: 6px;
  right: 10px;
  left: 10px;
  width: 20px;
  height: 3px;
  opacity: 0.6;
  background-color: #2F97BC;
  margin: 0;
  padding: 0; 
}


@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

#sec-code label:focus-within > hr {
  animation: blink 1s infinite reverse;
  animation-timing-function: linear;
  background-color: #ffffff;
}
