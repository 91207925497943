form.light-autofill input:-webkit-autofill,
form.light-autofill input:-webkit-autofill:hover,
form.light-autofill input:-webkit-autofill:focus,
form.light-autofill textarea:-webkit-autofill,
form.light-autofill textarea:-webkit-autofill:hover,
form.light-autofill textarea:-webkit-autofill:focus,
form.light-autofill select:-webkit-autofill,
form.light-autofill select:-webkit-autofill:hover,
form.light-autofill select:-webkit-autofill:focus {
  border: none;
  /* -webkit-text-fill-color: white; */
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

html {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.spenda-color {
  color: #1c78ad;
}

.old-theme-background {
  background: linear-gradient(180deg, #017095 0%, #003556 100%);
}

body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background: #f4f8fb;
  background-attachment: fixed;
}

body .zls-sptwndw {
  position: fixed;
  display: none;
  bottom: 0;
  cursor: pointer;
  z-index: 100000000000000000 !important;
}

body .zsiq_floatmain {
  position: fixed;
  z-index: 100000000000000000 !important;
  max-width: 305px;
  box-sizing: border-box;
}

input:focus,
input {
  outline: 0;
}

.bckg-spenda {
  background: linear-gradient(180deg, #017095 0%, #003556 100%);
}

#root {
  height: 100%;
}

h1 {
  text-align: center;
}

form {
  width: 100%;
  margin: 0 auto;
}

label,
input {
  display: block;
  width: auto;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: rgb(235, 54, 54);
  /* margin-top: -15px; */
  font-size: 14px;
  font-family: none !important;
  margin-bottom: 20px;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  width: 0;
  height: 0;
  border: 1.063em solid transparent;
  border-bottom-color: #004d71;
  border-top: 0;
  margin-left: -1.062em;
  margin-top: -1.062em;
}

/* Sticking the table header */
.sticky-table thead th {
  position: sticky;
  top: 0;
  background-color: #d5eaf2;
}

.public-form .MuiTextField-root {
  padding-bottom: 1.5rem;
}

.public-form p[id$='-helper-text'] {
  position: absolute;
  bottom: 5px;
}

.list-circle {
  list-style-type: circle;
}

.opacity-125 {
  opacity: 0.125;
}

button.rec-dot {
  display: none;
}

div.rec.rec-item-wrapper {
  height: 100%;
}

.rec.rec-arrow {
  background-color: #1c78ad;
  color: #fff;
  outline: none;
  animation: none;
  border: none;
}

.rec.rec-arrow:hover:enabled {
  background-color: #004d71;
  outline: none;
}

.rec.rec-arrow:focus:enabled {
  background-color: #017095;
  outline: none;
}

.rec.rec-arrow:active:enabled {
  background-color: #2c95bc;
  outline: none;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.modal-box {
  background-color: #fff;
  border-radius: 14px;
  height: 540px;
  width: 100%;
  position: relative;
}

.modal-right-content {
  margin-left: 25px;
}

.simple-modal-description {
  font-size: 22px;
}

.simple-modal-button {
  padding: 6px 0px 6px 8px !important;
  background-color: #1c78ad !important;
  max-width: 160px !important;
  min-width: 140px !important;
}

.fridayNextbtn {
  padding: 6px 0px 6px 8px !important;
}

.demo-setup-modal-box button {
  width: 150px;
  margin-left: 30px;
  padding: 6px 16px 6px 16px !important;
}

.simple-modal-description.text-lg {
  font-size: 18px;
}

.select-account-modal {
  font-size: 32px;
}

.cloud-app-box {
  width: 160px !important;
  background-color: #eff3f6 !important;
  color: #1c78ad !important;
  border-radius: 10px !important;
}

.sm-description {
  color: #909090;
  line-height: 26px;
}

.label .label,
.label {
  color: #666666 !important;
  font-size: 12px;
  padding-bottom: 3px;
}

.customer-description-para {
  font-size: 22px;
  color: #1c78ad;
}

.label input {
  background-color: #f1f1f1;
  padding: 10px 12px 10px;
  border-radius: 4px;
  border: 0px !important;
}

.label .MuiFormControl-root {
  width: 100%;
}

.completeSetupImg {
  width: 92%;
  position: relative;
}

.complete-robot-img {
  top: 15%;
  left: 18%;
}

.simple-modal-button.mt-4 {
  margin-top: 20px !important;
}

.clear-select .MuiInputBase-root {
  width: 100%;
}

.clear-select .MuiSelect-icon {
  color: #a9a9a9;
}

.clear-select .MuiSelect-root,
.clear-select .MuiSelect-root:focus {
  border: 0px;
  margin: 0px;
  box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%);
  font-size: 12px;
  background-color: #fff;
  width: 100%;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: left;
}

.clear-select span {
  color: red;
}

.MuiPopover-root .MuiListItem-root {
  justify-content: flex-start !important;
  font-size: 13px;
  font-family: inherit;
}

.account-box {
  height: 180px;
  width: 179px;
  border-radius: 10px;
  background-color: hsla(var(--primary) / 15%);
}

.account-box button {
  padding: 9px 35px !important;
  font-size: 14px !important;
}

.suggestion-box {
  height: auto;
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
}

.suggestion-box button {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 0px;
  color: #1c78ad !important;
  box-shadow: none !important;
}

.suggestion-box button span {
  color: #1c78ad !important;
}

.suggestion-box button:hover {
  background-color: transparent;
}

.common-next-btn {
  padding: 8px 24px 8px 32px !important;
  box-shadow: none !important;
  position: absolute !important;
  bottom: 0;
  margin-bottom: 20px !important;
}

.trial-btn {
  padding: 8px 24px 8px 32px !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

.common-next-btn .absolute {
  position: relative !important;
  right: 0px !important;
  margin-left: 10px;
}

.common-next-btn img {
  margin-left: 10px;
  margin-top: 2px;
}

.back-btn-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #999999;
  font-size: 32px !important;
  margin: -1px 0 0 -1px;
}

.template-Selection-modal .simple-modal-title {
  font-size: 32px;
  line-height: 40px;
}

.welcome-bot {
  margin-top: -33px;
}

.template-bot {
  margin: -93px 0px 0 -23px;
}

.myob-org-select-bot {
  margin-top: -60px;
}

.authorization-bot {
  margin-top: -70px;
}

.business-datail-bot {
  margin-top: -67px;
}

.fetch-customers-bot {
  margin-top: -130px;
}

.select-account-modal .simple-modal-title {
  line-height: 40px;
}

.account-img-col {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fetch-customer-row {
  height: 75%;
  position: relative;
}

.fetch-customer-row .simple-modal-title {
  line-height: 42px;
}

.payment-setup {
  margin: 15px 20px 10px 20px;
  position: relative;
}

#add-payment-setup {
  background-color: #fafafa;
  height: 345px;
  border-radius: 10px;
  position: relative;
}

.payment-close-icon {
  float: right;
  color: #cccccc;
  font-size: 18px;
  margin: -10px -8px 0px 0px;
}

#add-payment-setup .payment-setup {
  margin: 0px !important;
}

#add-payment-setup .table-submit {
  width: 91%;
  margin-bottom: 20px;
}

.add-user-form .MuiInput-underline:before,
.add-user-form .MuiInput-underline:after {
  display: none;
}

.upload-img-field {
  position: relative;
  border-radius: 8px;
  height: 110px;
  transition: 0.5s ease-in-out;
  border: 2px dashed #e2e2e2;
}

.upload-img-field img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.overlay-img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  transition: 0.5s ease-in-out;
  left: 0;
}

.overlay-img span {
  width: 32px;
  height: 32px;
  background-color: #f1f1f1;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-img span:hover {
  background-color: #fff;
}

.overlay-img svg {
  fill: #999999;
  padding: 0px;
  width: 20px;
  height: 20px;
}

.upload-img-field:hover .overlay-img {
  display: flex;
}

.drag-drop-placeholder-text {
  cursor: pointer;
}

.drag-drop-placeholder-text img {
  margin: 0 auto 5px;
}

.paceholder-big {
  font-size: 12px;
  color: #666666;
  padding-bottom: 5px;
}

.placeholder-small {
  font-size: 9px;
  color: #bfbfbf;
}

.file-error {
  font-size: 13px;
  color: red;
  font-weight: 400;
  padding-top: 2px;
}

.time-taken-para {
  font-size: 18px;
  color: #666666;
  line-height: 26px !important;
}

.invoice-btn {
  margin: 20px 10px 0 10px !important;
  position: relative !important;
  margin-bottom: 0px !important;
}

.refreshBtn {
  margin-right: 0px !important;
}

.addBtn {
  padding: 8px 24px 8px 24px !important;
}

#menu-clearsTo .MuiMenu-paper,
#menu-paymentMethod .MuiMenu-paper {
  margin-top: 1px;
  max-height: 250px;
}

.payment-setup-bot {
  margin: -69px 30px 0 0;
}

.account-refresh-btn {
  cursor: pointer;
  font-weight: 600;
}

.account-refresh-btn svg {
  width: 15px;
  margin: -3px 2px 0 5px;
}

.success-bot {
  position: absolute;
  top: 0;
  margin: -15px 30px;
}

.trial-heading {
  font-size: 32px;
  color: #666666;
  line-height: 42px;
}

.trial-heading span {
  font-weight: 600;
  color: #333;
}

.trial-time {
  box-shadow: 0px 0px 8px -2px rgb(0 0 0 / 20%);
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 21px;
  color: #666;
}

.trial-time span {
  display: block;
  font-size: 40px;
  font-weight: 900;
  color: #333;
  line-height: 38px;
}

.closeIcon {
  margin: -35px -23px 0 0;
  position: relative;
  right: 0;
  text-align: right;
}

.closeIcon svg {
  fill: #cccccc;
  width: 22px;
  cursor: pointer;
}

.closeIcon svg:hover {
  fill: #999;
}

.selling-days-left {
  border-radius: 4px;
  color: #e3a600;
  font-size: 12px;
  padding: 1px 7px;
  font-weight: 600;
  margin-bottom: 4px;
  width: auto;
}

/* .authorize-account-next-btn {
  margin-left: 24px !important;
} */
.delete-payment-icon {
  opacity: 0.4;
}

.organisation-list-box {
  width: 50%;
  background: #f4f4f4;
  min-height: 200px;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #83b9d7;
  overflow: hidden;
}

.org-login-text {
  font-size: 23px;
  text-align: center;
}

.organistion-name-text {
  display: inline-block;
  padding: 7px 29px 10px;
  background: white;
  color: #1c78ad;
  font-size: 21px;
  line-height: 24px;
  border-radius: 7px;
  border: 1px solid;
  cursor: pointer;
}

.selected-organisation {
  background: #1c78ad !important;
  color: white !important;
}

.payment-method-col {
  max-height: 330px;
  overflow-y: auto;
  background-color: #f7f7f7;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.subscription-heading {
  font-size: 32px;
  color: #1c78ad;
  line-height: 42px;
}

.subscription-heading span {
  font-weight: 600;
}

.subscription-bot {
  margin: -50px 0px 0px 0px;
}

.liveChat {
  padding: 8px 32px !important;
  position: relative !important;
  margin: 0px !important;
}

.liveChat img {
  margin-left: 0px;
}

.subscription-action-btn {
  padding: 0px 30px;
}

.subscription-action-btn p {
  font-size: 21px;
  color: #666666;
}

button.phoneNumber {
  font-weight: 500;
  font-size: 22px;
  color: #1c78ad;
}

.delete-image-cancel-btn {
  border: 1px solid #2f97bc !important;
}

/* --------------Live Chat Css------------- */
.live-chat-section {
  position: absolute;
  bottom: 0px;
  right: 10px;
  text-align: center;
  font-size: 12px;
}

.live-chat-section p {
  font-size: 12px;
  color: #666666;
  margin-bottom: 3px;
}

.liveChatBtn {
  border: 1px solid #1c78ad;
  border-bottom: 0px;
  border-radius: 5px 5px 0 0;
  color: #1c78ad;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 12px;
  background-color: #e3ecf1;
  display: flex;
}

.done-btn,
.purchase-now-btn {
  position: relative !important;
  margin: 0px !important;
}

.subscription-action-btn .liveChatBtn {
  padding: 0px !important;
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px !important;
}

.FetchCustomers-btns-sucess {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0px;
}

.FetchCustomers-btns-failed .common-next-btn {
  position: relative !important;
}

.FetchCustomers-btns-sucess .common-next-btn {
  position: relative !important;
  margin: 0px 0px 0px 10px !important;
}

.FetchCustomers-btns-sucess .primaryBtn {
  padding: 8px 32px !important;
}

.FetchCustomers-btns-sucess .primaryBtn span {
  color: #1c78ad !important;
}

.FetchCustomers-btns-sucess .primaryBtn img {
  display: none;
}

.after-success-row {
  margin-top: 14px;
}

.collect-modal-box {
  max-width: 932px;
  background-color: #f6f4f4;
  border-radius: 8px;
  padding: 0px 0px 30px 0px;
  margin: 30px auto 0px;
  width: 100%;
  max-height: 90%;
}

.spenda-collect-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 0px #9d9d9d;
  padding: 25px 25px 30px 30px;
}

.collect-left {
  width: 65%;
  float: left;
  padding-right: 0px;
}

.logo-box {
  width: 113px;
  height: 113px;
  border-radius: 16px;
  background: #e3a600;
  padding: 5px;
  float: left;
  margin-right: 26px;
}

.menu-logo-box {
  width: 113px;
  height: 113px;
  border-radius: 16px;
  padding: 5px;
  float: left;
  margin-right: 26px;
}

.collect-details {
  float: left;
  width: 60%;
}

.collect-details h3 {
  font-size: 20px;
  color: #6d6d6d;
  font-weight: 500;
  font-family: 'Poppins';
  margin: 2px 0px 8px 0px;
}

.collect-details p {
  font-size: 16px;
  color: #616161;
  font-family: 'Poppins';
  font-weight: 400;
}

.collect-right {
  width: 318px;
}

.collect-right button {
  width: 100% !important;
  position: relative !important;
  background-color: #1886a9;
}

.collect-action-btn button {
  width: 152px !important;
  text-align: center;
  background-color: #eafaff;
  border-radius: 4px;
  padding: 8px 10px !important;
  color: #1e85a7 !important;
  font-weight: 700;
  box-shadow: none !important;
  margin: 0 !important;
}

.collect-action-btn button span {
  color: #1e85a7 !important;
}

.collect-2-columns {
  padding: 36px 30px 0px;
}

.collect-2-columns-col {
  padding-right: 60px;
}

.collect-2-columns-col h2 {
  font-size: 16px;
  color: #616161;
  font-weight: 700;
  margin-bottom: 10px;
}

.collect-2-columns-col p {
  font-size: 16px;
  color: #616161;
  font-weight: 400;
  margin-bottom: 15px;
}

.spenda-collect-img {
  padding: 36px 28px 0px;
}

.spenda-collect-img img {
  width: 24%;
}

.collect-instructions {
  padding: 36px 30px 0px;
}

.instruction-points {
  color: #616161;
  font-size: 16px;
  line-height: 20px;
  width: 252px;
  padding-bottom: 12px;
}

.instruction-points svg {
  fill: #1e85a7;
  width: 10px;
  height: 10px;
  margin-right: 20px;
  margin-top: 5px;
}

.template-selection-modal {
  margin: 35px 0;
}

.spenda-collect-content {
  overflow-y: scroll;
  width: 100%;
}

.onboarding-close-icon {
  float: right;
  right: 10px;
  top: 5px;
  position: absolute;
  margin: 0;
}

.back-btn-bg-image {
  display: inline-block;
  margin-top: -23px;
}

.bubble {
  position: relative;
  width: 250px;
  height: 120px;
  padding: 0px;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: #d6d2d2 solid 1px;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: #fff transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -20px;
  left: 105px;
}

.bubble:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: #d6d2d2 transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -21px;
  left: 105px;
}

.anz {
  width: 118;
  background-color: #1133dd;
  background-image: linear-gradient(
    17deg,
    #1e5799 0%,
    #1e5799 12%,
    #207cca 37%,
    #2989d8 51%,
    #2989d8 64%,
    #2989d8 83%,
    #7db9e8 100%
  );
  /* overflow: hidden; */
}

.anz::before,
.card::after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 70%;
}

.anz::after {
  top: 50%;
  right: 40%;
  width: 45%;
  height: 65%;
  background-image: radial-gradient(ellipse at bottomleft, #b2e1ff 0%, #66b6fc 30%, transparent 100%);
  opacity: 0.19;
  box-shadow:
    4px 2px 6px #b2e1ff,
    4px 2px 3px #ffffff,
    1000px 7px 6px #66b6fc,
    1000px 6px 4px #223355;
}

.anz::before {
  top: 37%;
  left: 25%;
  width: 45%;
  height: 65%;
  background-image: radial-gradient(ellipse at bottom left, #b2e1ff 0%, #66b6fc 30%, transparent 100%);
  opacity: 0.37;
  box-shadow:
    -4px -2px 6px #b2e1ff,
    -4px -2px 3px #ffffff,
    -180px -2px 6px #66b6fc,
    -180px -2px 4px #223355;
}

/* *Zoho Chat Widget */
.zsiq_floatmain.zsiq_theme1.siq_bR {
  left: 0;
  right: auto;
}

.zsiq_floatmain.zsiq_theme1.siq_bR > #titlediv {
  display: none;
}

.rounded {
  border-radius: 0.25rem;
}

.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
  background-color: #ddd;
}

/* -------Punchasing Css Start ----------*/

.select-invoice-card {
  width: 517px;
  height: 300px;
  padding: 50px;
  font-size: 22px;
}

.section-invoices {
  border-bottom: 1px solid #d8d8d8;
  padding: 9px 13px;
}

.invoice-list-card .supplier-name {
  font-size: 18px;
  font-weight: 500;
  position: relative;
  width: 90%;
}

.invoice-list-card .invoice-no {
  font-size: 14px;
  font-weight: 400;
}

.invoice-list-card .total-container {
  margin: 5px 0px 17px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.invoice-list-card .total-overdue-container .supplier-name:after {
  content: '';
  position: absolute;
  height: 6px;
  width: 100%;
  background-color: #c55d44;
  border-radius: 6px;
  left: 0;
  bottom: -10px;
}

.invoice-list-card .total-due-container .supplier-name:after {
  content: '';
  position: absolute;
  height: 6px;
  width: 100%;
  background-color: #d2a656;
  border-radius: 6px;
  left: 0;
  bottom: -10px;
}

.invoice-list-card .total-paid-container .supplier-name:after {
  content: '';
  position: absolute;
  height: 6px;
  width: 100%;
  background-color: #4da128;
  border-radius: 6px;
  left: 0;
  bottom: -10px;
}

.invoice-list-card .due-container {
  /* border: 2px solid #dcb871; */
  /* background-color: #f4e9d2; */
  margin: auto 0;
  border-radius: 6px;
  /* color: #cf9d39; */
  text-align: center;
}

.invoice-list-card .overdue-container {
  background-color: #e9cfc8;
  margin: auto 0;
  border-radius: 6px;
  color: #934532;
  text-align: center;
  padding: 0 5px;
}

.due-container p:first-child {
  font-weight: 300;
  font-size: 14px;
}

.due-container p:last-child {
  font-weight: 500;
}

button.pay-btn {
  width: 100%;
  height: 50px;
  box-shadow: none !important;
  font-size: 14px;
  background-color: #3c9f78;
  font-weight: 400;
  color: #f1f1f1;
}

button.pay-btn span {
  display: block;
}

button.pay-btn span span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}

button.pay-btn:hover {
  background-color: #3c9f78;
  text-decoration: underline;
}

.select-Invoice-card {
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

button.cancel-btn {
  background-color: #c68a19;
  height: 47px;
  box-shadow: none !important;
  font-size: 18px;
}

button.cancel-btn:hover {
  background-color: #c68a19;
  text-decoration: underline;
  color: #fff;
}

#purchase-invoice-list {
  padding: 10px;
  position: relative;
}

.select-symbol {
  display: flex;
  font-size: 20px;
  color: #1c78ad;
  font-weight: 500;
  margin: 0px 0px 0px 13px;
}

.select-symbol img {
  width: 30px;
  height: 30px;
  margin-right: 13px;
  cursor: pointer;
  margin-top: 0 !important;
}

.select-symbol-icon {
  width: 31px;
  height: 31px;
  margin-right: 13px;
  margin-top: 17px;
  cursor: pointer;
}

.invoice-details {
  padding: 10px;
}

.invoice-details p {
  font-size: 14px;
  margin-bottom: 3px;
  line-height: 22px;
  color: #333;
}

.invoice-head-right {
  text-align: right;
}

.amount-due {
  border: 3px solid #e1e1e1;
  border-radius: 13px;
  text-align: center;
  padding: 3px;
  margin-bottom: 9px;
}

.amount-due p {
  margin: 0px;
}

.amount-due p:last-child {
  font-size: 16px;
  font-weight: 600;
}

.invoice-table {
  padding: 0px 10px;
}

.invoice-table p {
  font-size: 18px;
  color: #333333;
  line-height: 30px;
}

.invoice-table-col {
  margin-bottom: 50px;
}

.invoice-table-col .invoice-table-col {
  margin: 25px 0 0 0;
}

.invoice-table-col thead {
  background-color: #f6f6f6;
  border-radius: 2px;
}

.invoice-table-col thead th {
  font-weight: 400;
  padding: 5px 20px;
  text-align: left;
  color: #444343;
  font-size: 14px;
  border-bottom: 0;
}

.invoice-table-col thead th:first-child {
  padding-right: 0;
  width: 38px;
}

.invoice-table-col td {
  padding: 5px 20px;
  border-bottom: 2px solid #f8f8f8;
  color: #333333;
}

.invoice-table-col td:first-child {
  padding-right: 0;
  width: 38px;
}

.invoice-table-col tr:last-child td {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 0;
  border-top: 2px solid rgba(204, 204, 204, 0.4);
}

.invoice-table-col .MuiSvgIcon-colorError {
  fill: #c55d44;
  font-size: 17px;
  margin-left: -10px;
  margin-right: 5px;
}

/* .invoice-subtotal {
  width: 282px;
  float: right;
  margin-bottom: 20px;
}
.invoice-subtotal td {
  width: 50%;
  padding: 5px 20px;
  border-bottom: 1px solid #eaeaea;
} */

.total-invoice td {
  font-weight: 400;
  font-size: 14px;
  background-color: #f6f6f6;
  border-bottom: 1px solid #eaeaea;
  text-align: right;
}

.table-y-scroll {
  height: 298px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 5px;
}

.invoice-bottom-btn {
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 9px;
  width: calc(100% - 18px);
  float: left;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
}

.invoice-bottom-btn button {
  font-size: 18px;
  padding: 8px 30px;
  height: 40px;
  box-shadow: none;
}

.selected-invoice-card {
  background-color: #e6f2f8;
}

.invoice-list-col {
  padding: 10px 0px !important;
}

.selected-invoice-payment {
  font-size: 24px;
  font-weight: 500;
  color: #444343;
  padding: 0 10px 10px;
}

.selected-invoice-payment .ammount {
  color: #3d9f78;
}

.supplier-expand-more {
  border: 1px solid #0082ba;
  border-radius: 50%;
  color: #0082ba;
}

.invoice-list-breakdown-tbl {
  padding-left: 35px;
}

.invoice-list-breakdown-tbl .select-symbol-icon {
  margin: 0px auto !important;
  width: 25px;
  height: 25px;
}

.invoice-list-breakdown-tbl .MuiTableCell-body {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
}

.invoice-list-breakdown-tbl .MuiTableCell-head {
  color: #999999;
  font-size: 12px;
  font-weight: 500;
}

.invoice-list-breakdown-tbl .MuiTableCell-root {
  border-bottom: 1px solid #f8f8f8;
  padding: 12px 16px;
}

.section-purchaseInvoices {
  padding: 10px 5px 10px 13px;
  border-bottom: 1px solid #d8d8d8;
}

.rotate-expand-more-icon img {
  transition: transform 0.4s ease-in-out;
  transform: rotate(180deg);
  color: #0082ba;
  cursor: pointer;
}

.rotate-expand-less-icon svg {
  transition: transform 0.4s ease-in-out;
  transform: rotate(0deg);
  color: #0082ba;
  cursor: pointer;
}

.invoice-list-supplier-name {
  width: 220px;
  margin-top: 10px;
}

.overdue-by-date {
  color: #934532 !important;
}

.due-by-date {
  color: #d2a656 !important;
}

.overdue-by-date span {
  font-weight: 300;
  font-size: 16px;
}

.selected-invoice-payment-hr {
  width: 70%;
  margin: 10px auto 20px;
  border-color: #e1e1e1;
}

.payment-breakdown-heading {
  font-size: 20px;
  font-weight: 500;
  color: #444343;
}

table.invoice-payment-breakdown-tbl {
  width: 400px;
  float: right;
  margin-top: 0 !important;
}

.payment-break-down-tbl-container {
  width: 100%;
  float: left;
  max-height: 60vh;
  overflow-y: auto;
  margin: 12px 0 0px 0;
}

.no-result-found {
  text-align: center;
  width: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  font-size: 16px;
  opacity: 0.5;
  margin: 10px 0px 0px;
}

.centerSvg svg {
  margin: 0 auto;
}

.whitespace-pre-wrap {
  grid-template-rows: unset !important;
}

.expand-more-svg {
  align-items: center;
  display: flex;
  margin-left: 5px;
  cursor: pointer;
  min-width: 17px;
  max-width: 17px;
  width: 100%;
}

.total-invoice td.empty-td {
  background-color: transparent;
  border-bottom: 0px !important;
  border-top: 0px !important;
}

.expandable-row td {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.expandable-row td:first-child {
  padding: 0 !important;
}

.expandable-row .invoice-table-col td:first-child {
  padding: 0 20px !important;
  text-align: left;
  width: 123px;
}

.expandable-row .invoice-table-col td:last-child {
  text-align: left;
  font-size: 14px;
  width: 50px;
  padding: 0px 8px !important;
  color: #c55d44;
}

.expandable-row .invoice-table-col td:last-child svg {
  width: 18px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .invoice-list-supplier-name {
    width: 150px;
  }
}

hr {
  border-color: rgb(204, 204, 204);
}

.module-info-slides .slide {
  margin: auto !important;
}

.module-info-slides .selected .selectedItem img,
video {
  height: 210px !important;
  width: 365px !important;
}

@media (max-width: 640px) {
  .module-info-slides .selected .selectedItem img,
  video {
    height: 230px !important;
  }

  .custom-carousel-previous {
    display: none !important;
  }

  .custom-carousel-next {
    display: none !important;
  }
}

.custom-carousel-previous {
  position: absolute;
  width: 216px;
  left: 0 !important;
  background: linear-gradient(to right, #f8f8f8, transparent 100%);
  background-size: cover;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.custom-carousel-next {
  position: absolute;
  width: 216px;
  right: 0 !important;
  background: linear-gradient(to left, #f8f8f8, transparent 100%);
  background-size: cover;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  padding-right: 30px;
  justify-content: flex-end;
}

.previous-item {
  text-align: right;
}

.next-item {
  text-align: left;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
}

td.MuiTableCell-alignLeft.ellpsisCol {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packItemOverflowX::-webkit-scrollbar {
  height: 5px;
}

.packItemOverflowX::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
.packItemOverflowX::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 2px;
}

/* Handle on hover */
.packItemOverflowX::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.ap-onboarding-close-icon {
  margin: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid hsl(var(--primary));
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 55;
}

.ap-onboarding-close-icon .closeIcon svg {
  fill: 'hsl(var(--primary))';
}

#menu-selectBatchType .MuiMenu-paper,
#menu-selectBatchPeriod .MuiMenu-paper,
#menu-selectViewType .MuiMenu-paper,
#menu-selectTimelineRange .MuiMenu-paper,
#menu-selectPaymentMethod .MuiMenu-paper,
#menu-selectPaymentDesc .MuiMenu-paper,
#menu-selectManageScheduler .MuiMenu-paper {
  min-width: 185px !important;
  margin: 10px 0 0 0px;
  border: 1px solid #1c78ad;
  box-shadow: none;
}

#menu-selectManageScheduler .MuiMenu-paper {
  min-width: 115px !important;
}

#menu-searchCategory .MuiMenu-paper {
  min-width: auto !important;
  max-width: 100% !important;
  margin: 4px 0 0 0px;
  border: 1px solid #1c78ad;
  box-shadow: none;
}

#menu-selectPaymentMethod .MuiMenu-paper {
  margin: 15px 0 0 0px;
  min-width: 260px !important;
  max-height: 320px;
}

#menu-selectPaymentDesc .MuiMenu-paper {
  margin: 15px 0 0 -7px;
  min-width: 242px !important;
}

#menu-selectBatchType .MuiListItem-root,
#menu-selectViewType .MuiListItem-root,
#menu-selectBatchPeriod .MuiListItem-root,
#sortTypePopupState .MuiListItem-root,
#menu-selectPaymentMethod .MuiListItem-root,
#menu-selectPaymentDesc .MuiListItem-root,
#menu-selectTimelineRange .MuiListItem-root,
#menu-selectManageScheduler .MuiListItem-root {
  font-size: 14px;
  font-family: 'poppins';
  font-weight: 600;
  padding: 10px !important;
}

#menu-selectBatchType .MuiListItem-root:hover,
#menu-selectViewType .MuiListItem-root:hover,
#menu-selectBatchPeriod .MuiListItem-root:hover,
#sortTypePopupState .MuiListItem-root:hover,
#menu-selectPaymentMethod .MuiListItem-root:hover,
#menu-selectPaymentDesc .MuiListItem-root:hover,
#menu-selectTimelineRange .MuiListItem-root:hover,
#menu-selectManageScheduler .MuiListItem-root:hover,
#menu-searchCategory .MuiListItem-root:hover {
  background-color: rgba(211, 229, 239, 0.5);
}

#menu-searchCategory .MuiListItem-root {
  font-size: 14px;
  font-family: 'poppins';
  font-weight: 600;
  padding: 10px !important;
  display: block;
  text-align: center !important;
}

#menu-selectTimelineRange .MuiListItem-root .MuiRadio-root {
  padding: 0px;
  margin-right: 8px;
}

#menu-selectTimelineRange .MuiListItem-root .MuiSvgIcon-root {
  fill: #1c78ad;
  width: 20px;
  height: 20px;
}

.apSelect-symbol-icon {
  width: 22px !important;
  height: 22px !important;
  min-width: 22px;
  cursor: pointer;
}

#sortTypePopupState .MuiPaper-root {
  min-width: 230px !important;
  margin: 4px 0 0 0px;
  border: 1px solid #1c78ad;
  box-shadow: none;
}

#sortTypePopupState .MuiListItem-root {
  border-bottom: 1px solid #d8d8d8;
}

#sortTypePopupState .MuiFormControl-root {
  padding: 0px 0px 0px 8px;
  display: inline-block;
  margin-top: 10px;
  width: 100px;
}

#sortTypePopupState .MuiInputBase-root {
  border: 1px solid #1c78ad;
  border-radius: 6px;
  height: 40px;
  margin-top: 10px;
}

#sortTypePopupState .MuiInputBase-root:before,
#sortTypePopupState .MuiInputBase-root:after {
  display: none;
}

#sortTypePopupState input {
  font-size: 13px;
  color: #333;
  font-family: 'poppins';
  padding: 10px;
}

#sortTypePopupState input::placeholder {
  font-size: 11px;
}

#sortTypePopupState .MuiFormLabel-root {
  color: #707070 !important;
  font-size: 12px;
  font-family: 'poppins';
  position: relative;
  transform: unset;
}

#searchInputPopup .MuiFormControl-root {
  height: 40px;
  background: #fff;
  padding-left: 5px;
}

#searchInputPopup .MuiInputBase-root.Mui-focused {
  background: transparent !important;
}

#searchInputPopup .MuiIconButton-root {
  border-radius: 0px 6px 5px 0px !important;
  height: 40px !important;
  font-size: 15px !important;
  font-family: 'Poppins';
  background: #1c78ad !important;
}

#searchInputPopup input {
  font-family: 'poppins';
  font-size: 14px;
}

#searchInputPopup .MuiInputBase-root.Mui-focused input#search-text-input {
  color: #333;
}

#searchInputPopup .MuiInputBase-root.Mui-focused button#go-btn-input {
  color: white;
  width: auto;
  margin: 0px;
}

#searchInputPopup .MuiInputBase-root.Mui-focused .MuiIconButton-label {
  color: white;
}

#searchInputPopup .inputCleanIcon {
  background: transparent !important;
  right: -0.5em;
  color: #1c78ad;
}

/* Chatbox subject select popup css */
#menu-subject {
  background: transparent !important;
}

#menu-subject .MuiPaper-root {
  width: 221px;
  margin-top: -104px;
  max-height: 205px;
  overflow-y: auto;
}

#menu-selectBatchType,
#menu-selectViewType,
#menu-selectBatchPeriod,
#menu-searchCategory,
#menu-selectClaimReason,
#menu-selectClaimAction {
  background: transparent !important;
}

#menu-selectBatchType .MuiPaper-root,
#menu-selectViewType .MuiPaper-root,
#menu-selectBatchPeriod .MuiPaper-root {
  margin-top: 0px;
}

#menu-resolveConversation {
  background: transparent !important;
}

/* Chatbox subject select popup css */

.mainEventWrap {
  position: relative;
}

.mainEventWrap::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  background: #d8d8d8;
  left: 90px;
}

#APCardModal .MuiDialog-paperWidthMd {
  max-width: 445px;
  margin: 0px;
  border-radius: 20px;
}

#APCardModal .MuiTypography-root {
  padding-top: 0px;
  font-family: 'poppins';
  font-size: 20px;
  text-transform: capitalize;
}

/* Rebranding text field new theme */
.rebrandingTxtField .MuiFormControl-root {
  padding-bottom: 0px;
  margin-bottom: 27px;
}

.rebrandingTxtField .MuiOutlinedInput-root {
  height: 45px;
  background-color: #fff;
  border-radius: 6px;
}

.rebrandingTxtField .MuiFormLabel-root {
  color: #bbbbbb;
  font-family: 'poppins';
  width: auto;
  font-weight: 600;
  font-size: 14px;
  top: -6px;
  left: -5px;
}

.rebrandingTxtField .MuiInputLabel-shrink {
  top: 0px;
  left: 0;
  padding-right: 2px;
  margin-left: -2px;
  color: #333;
}

.rebrandingTxtField .MuiOutlinedInput-notchedOutline {
  border-color: #333;
}

.rebrandingTxtField .MuiOutlinedInput-notchedOutline legend {
  height: 5px;
}

.rebrandingTxtField .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #333 !important;
  border-width: 1px;
}

.rebrandingTxtField .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #333 !important;
}

.rebrandingTxtField .MuiFormLabel-root.Mui-focused {
  color: #333;
}

.rebrandingTxtField .MuiInputBase-input {
  font-size: 14px;
  font-weight: 400;
  padding: 0px 8px;
  font-family: 'poppins';
  color: #333 !important;
}

.rebrandingTxtField .MuiIconButton-root {
  color: #1c78ad;
}

.rebrandingTxtField .MuiInputBase-adornedEnd {
  padding-right: 0px;
}

.rebrandingTxtField .MuiInputBase-adornedStart {
  padding-right: 0px;
  padding-left: 7px;
}

.rebrandingTxtField .MuiFormHelperText-root.Mui-error {
  font-family: 'poppins';
}

.rebrandingTxtField .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.rebrandingTxtField .MuiOutlinedInput-input:-webkit-autofill {
  -webkit-text-fill-color: #333;
}

#APCardModal button {
  font-family: 'poppins';
  width: 100px;
  height: 40px;
  font-size: 14px;
  border-radius: 6px !important;
}

.tableCellHeight .MuiTableCell-root {
  padding: 10px !important;
  height: 40px;
  border-bottom: 0px;
  border-left: 0px;
}

.tableCellHeight .MuiTableHead-root {
  background: #f1f1f1;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}

.tableCellHeight .MuiTableCell-body {
  border: 1px solid #f1f1f1;
}

.tableCellHeight .MuiTableCell-body:nth-child(odd) {
  background: #fafafa;
}

.tableCellHeight .MuiTableCell-body:first-child {
  border-left: 1px solid #fafafa;
}

.tableCellHeight .MuiTableCell-body:last-child {
  border-right: 1px solid #fafafa;
}

.editableInvoiceText.MuiTableCell-root {
  padding: 1px !important;
}

.editableInvoiceText fieldset {
  border: 0px;
}

.editableInvoiceText .MuiFormControl-root .MuiInputBase-input {
  padding: 0px 10px !important;
}

.editableInvoiceText .MuiInputAdornment-positionEnd {
  width: 35px;
}

.invoiceTableCell .supplierName {
  display: block;
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#menu-apPaymentMethod.MuiPopover-root,
#menu-apClearsTo.MuiPopover-root {
  background-color: transparent !important;
}

#menu-apPaymentMethod .MuiPaper-root,
#menu-apClearsTo .MuiPaper-root {
  border: 1px solid #1c78ad;
  border-top: 0px;
  border-right: 1px solid #1c78ad;
  border-radius: 0px 0px 6px 6px;
  margin: 6px 0px 0px -1px;
  box-shadow: none;
  max-height: 250px;
  /* min-width: 325.5px !important; */
}

#menu-apPaymentMethod .MuiPaper-root::-webkit-scrollbar-track,
#menu-apClearsTo .MuiPaper-root::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0px 0px 6px 0px;
}

#menu-apPaymentMethod .MuiPaper-root::-webkit-scrollbar-thumb,
#menu-apClearsTo .MuiPaper-root::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0px 0px 6px 6px;
}

#menu-apPaymentMethod li,
#menu-apClearsTo li {
  font-size: 14px;
  font-weight: 600;
  font-family: 'poppins';
  padding: 10px 10px;
}

#menu-apPaymentMethod li:last-child,
#menu-apClearsTo li:last-child {
  border-bottom: 0px;
}

#menu-apPaymentMethod li:hover,
#menu-apClearsTo li:hover {
  background: #d3e5ef50;
}

/* credit-notes-list-dialog */
#creditListDialog .MuiDialogContent-root {
  padding: 8px 12px;
}

#creditListDialog .MuiDialogTitle-root {
  padding: 18px 12px;
}

#creditListDialog .MuiDialog-paper {
  z-index: 1000;
  position: absolute;
  font-size: 18px;
  max-width: 797px;
  max-height: 577px;
  min-height: 577px;
  font-weight: 500;
  justify-content: center;
  width: 100%;
}

#creditListDialog .MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 36px 8px 20px;
  align-items: center;
  justify-content: center;
}

/* credit-notes-list-dialog */

.font-pop p,
.font-pop div,
.font-pop label,
.font-pop span {
  font-family: 'Poppins';
}

/* Unallocated prepayment available Tooltip css */
#prepaymentPrompt {
  pointer-events: visible;
}

/* AR CSS classes groupped */

.arAdvanceSelectMenuPaper {
  max-height: 200px !important;
}
.arAdvanceSelectPopover .MuiMenu-paper {
  min-width: inherit !important;
  margin: 10px 0 0 0px;
  border: 1px solid #1c78ad;
  border-radius: 0px 0px 4px 4px;
  box-shadow: none;
}
.arAdvanceSelectPopover .MuiListItem-root {
  font-size: 14px;
  font-family: 'poppins';
  font-weight: 600;
  padding: 10px !important;
}
.arAdvanceSelectPopover .MuiListItem-root {
  border-bottom: 1px solid #ccc;
}
.arAdvanceSelectPopover .MuiListItem-root:last-child {
  border-bottom: none;
}
.arAdvanceSelectPopover .MuiListItem-root:hover {
  background-color: rgba(211, 229, 239, 0.5);
}
.arAdvanceSelectPopover {
  background: transparent !important;
}
.arAdvanceSelectPopover .MuiPaper-root {
  margin-top: 0px;
}

/* Manage User Popup Css */
#userDialog .MuiDialogContent-root {
  overflow-x: hidden;
}

#userDialog .MuiDialogTitle-root,
#discardPopup .MuiDialogTitle-root {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

#userDialog .MuiDialogTitle-root h2,
#discardPopup .MuiDialogTitle-root h2 {
  font-family: 'poppins';
  color: #333;
  font-weight: 300;
  font-size: 28px;
}

#userDialog .MuiDialogActions-root,
#discardPopup .MuiDialogActions-root {
  border-top: 1px solid #ccc;
  padding: 12px 28px;
}

.groupName::after {
  content: '';
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  display: block;
  left: -4px;
  top: -11px;
  z-index: 1;
}

#discardPopup .MuiDialogActions-root {
  justify-content: space-between;
}

.userDialogNewFooterDesign .MuiDialogActions-root {
  border-top: 0px !important;
  padding: 12px 10px !important;
}
.userDialogNewFooterDesign .MuiPaper-root {
  min-height: 550px;
}

/* -----Disclaimer Modal -----*/
#disclaimerModal .spenda-color {
  backdrop-filter: blur(5px);
}

/* ----AP Onb Wrap----- */
.wrapper {
  height: calc(100vh - 130px);
  height: calc(100svh - 130px);
}
.APCheckboxField .MuiFormGroup-root {
  flex-wrap: nowrap;
  flex-direction: row;
}
.APCheckboxField input {
  border-color: blue;
}
.APCheckboxField .MuiFormControlLabel-root:last-child {
  margin-right: 0px;
}
.APCheckboxField .Mui-checked {
  fill: hsl(var(--primary)) !important;
  color: hsl(var(--primary)) !important;
  border-radius: 4px;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: hsl(var(--primary)) !important;
}
.MuiRadio-colorSecondary.Mui-disabled {
  color: rgba(28, 120, 173, 0.5) !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: rgba(0, 0, 0, 0.4) !important;
}

/* AR Slide animation */

.animateARImageSlider {
  position: relative;
  animation: imageSlider 1s linear 0s 1 alternate;
  overflow-x: hidden;
}

@keyframes imageSlider {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* AR Expanded Search Bar */

#ar-expaned-search-bar .MuiOutlinedInput-root {
  border-radius: 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 6px;
}

/* AR Create Email Template Textare */
.arCreateEmailTemplateTextarea {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 1px solid #f1f1f1;
  font: medium -moz-fixed;
  font: -webkit-small-control;
  height: 236px;
  overflow: auto;
  padding: 10px;
  resize: vertical;
  width: 70%;
  background-color: #fafafa;
}

.arCreateEmailTemplateTextareaTags {
  font-size: 10px;
  font-weight: 500;
  background: #e3eef5;
  color: #1c78ad;
  padding: 5px;
  border-radius: 6px;
  display: inline;
  user-select: none;
}

.quoteTable td,
tr {
  padding: 0px !important;
}
/* ----------Happy Flow Css-------------- */
.happyFlowBg {
  background: url(./assets/jpg/HappyFlowBg.png) center no-repeat;
}

.bg-stripe {
  background: repeating-linear-gradient(114deg, #ececec, #ececec 1px, #f6f6f6 1px, #f6f6f6 8px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}