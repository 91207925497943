.processingSpinIcon {
  left: 3%;
  top: 50%;
  animation: animationFramesOne 5s infinite linear;
  -webkit-animation: animationFramesOne 5s infinite linear;
}
@keyframes animationFramesOne {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
