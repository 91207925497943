.payment-authority-container{
  font-family: 'Poppins';
  padding: 0 2.125rem
}

.authentication-desc{
  font-size: 12px;
  padding: 38px 14px 44px;
  font-weight: 400px;
}
.authentication-settings-desc{
  font-size: 12px;
  padding: 38px 21px 44px;
  font-weight: 400px;
}

.header-text{
  font-size: 22px;
  font-weight: 300;
}

.selected-authorisers-container{
  height: 200px;
  overflow-y: auto;
}