.notifications-container{
  width: 420px;
  min-height: auto;
  max-height: 400px;
  overflow-y: auto;
  font-family: 'poppins';
}

.notification-container{
  padding: 15px 35px 15px 10px;
  border-bottom: 1px solid #979797;
}

.notification-container p{
  font-size: 12px;
}