@tailwind base;
@tailwind components;
@tailwind utilities;

.spenda-powered {
  width: 160px;
  height: 130px;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  border-top-right-radius: 7rem;
  background-color: #fff;
}
@media (width <= 600px) {
  .spenda-powered {
    transform: scale(0.5);
    left: -40px;
    bottom: -32px;
  }
}

.spenda-powered .brand-name {
  margin-top: -2rem !important;
}

#payment-setup-description {
  font-size: 32px;
  color: #1c78ad;
}

.payment-setup-table {
  margin: 0px;
  background-color: #f7f7f7;
}

.payment-setup thead {
  background-color: #edf1f4;
  border-radius: 5px 5px 0px 0px;
}
.payment-setup-table th {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding: 9px 15px !important;
}
.payment-setup-table td {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
  padding: 9px 15px 3px !important;
}
.payment-setup-table input {
  height: 30px;
  border: 0px;
  margin: 0px;
  box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%);
  font-size: 12px;
}

.payment-setup-table #payment-setup-description {
  font-size: 15px;
}
#payment-setup {
  position: relative;
}
.table-submit {
  margin: 0px auto 20px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.table-submit .MuiBox-root {
  align-items: center;
}
.table-submit .MuiBox-root .common-next-btn {
  position: relative !important;
  margin: 0px !important;
}
.primaryBtn {
  margin-right: 20px !important;
  background-color: #fff !important;
  color: #1c78ad !important;
  border: 1px solid #1c78ad !important;
  padding: 6px 32px !important;
  box-shadow: none !important;
}
.primaryBtn:hover {
  background-color: rgba(29, 120, 173, 0.1) !important;
  color: #1c78ad !important;
}

#add-payment-setup #payment-setup-description {
  color: #4d4d4d;
}

.color-gray {
  color: gray !important;
}
